.home {
    /* padding-top: 80px; */
    min-height: 100vh;
    background-color: #C0DAEA;
}


.hero { 
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px;
    background-color: #BAD7E9;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 0;
}

.heroImg:nth-child(1) {
    width: 40%;
}

.heroImg:nth-child(2) {
    width: 30%
}

.carousel {
    background-color: #2B3467;
    padding-left: 30px;
    padding-top: 30px;
    margin: 20px !important;
    border-radius: 10px;
}

.tile {
    width: 450px;
    height: 450px;
    background-color: #FCFFE7;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-size: 20px;
}

.button {
    display: block;
    background-color: #EFBC9B;
    color: #363636;
    width: 200px;
    font-size: 16px;
    text-align: center;
    padding: 15px;
    font-weight: bold;
    margin-top: 20px;
}

.ourMission {
    background-color: #EFBC9B;
    margin: 20px;
    border-radius: 20px;
    padding: 20px;
}

.ourMissionHeading {
    display: block;
    margin: 0 auto;
    margin-top: 100px;
}
 
.ourMissionContent {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
}

.columns {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.column {
    width: 30%;
}

.aboutUs {
    background-color: #FCFFE7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.aboutUsHeading {
    background-color: #6A5D7B;
    color: white;
    padding: 10px;
    margin: 10px 20px;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.aboutUsContent {
    margin: 17px;
}

.ourProgressHeading {
    font-weight: bold;
    display: flex;
    /* justify-content: space-evenly; */
    gap: 20px;
    align-items: center;
}

.ourProgress div {
    background-color: #FCFFE7;
    width: 80%;
    padding: 10px;
    margin: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-left: 20px;
}