
.columns {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
}

.column1 {
    width: 30%;
    text-align: center;
}

.donationContent {
    font-size: 35px;
    font-family: Arial, Helvetica, sans-serif;
}
.container{
    position:absolute;
    top: 0%;
}

.priceGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-bottom: 50px;
}

.priceGrid div {
    background-color: #BAD7E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;
    width: 200px;
    text-align: center;
}

.submitButton {
    display: block;
    margin: 40px auto;
    width: 200px !important;
    display: flex;
    gap: 30px;
    height: 40px;
}
.bgimg{
    width: -webkit-fill-available;
    display: inline;
}
.globe{
   display: block;
}