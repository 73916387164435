.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    background-color: #B1CFEB;
    z-index: 10;
}

.navItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 80%;
    margin-left: 20px;
}   

.logo {
    /* margin-right: 20px; */
    height: 60px;
}

a {
    color: black;
    text-decoration: none;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}
.leafimg{
    position: absolute;
    top: 10%;
    right: 3%;
    height: 23vh;
    mix-blend-mode: difference;
    color: #272e5e;
    filter: invert(1);
}