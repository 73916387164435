.slotButton {
    /* opacity: 0.5; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;
    width: 150px;
    border: none;
    background-color: white;
}

.slotButton:hover {
    background-color: black;
    color: white;
}

.selected {
    background-color: black;
    color: white;
}