.bgimg{
    width: -webkit-fill-available;
    display: inline;
}
.loginCard{
    text-align: center;
    position: fixed;
    top: 16%;
    left: 35%;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    font-size: 2em;
    width: 30vw;
}
.textfield{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    /* From https://css.glass */
    background: linear-gradient(45deg, rgba(71, 186, 237, 0.42), #ffffff);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(71, 186, 237, 0.3);
    height: 50vh;
}