.box {
    width: 170px;
    height: 170px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.top {
    font-size: 12px;
}

.middle {
    font-size: 40px;
}

.bottom {
    font-size: 11px;
}