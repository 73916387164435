.patientList {
    width: 500px;
    /* margin-left: 70px; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
    border-radius: 15px;
}

.listHeading {
    background-color: #1F677D;
    color: white;
    height: 80px;
    border-radius: 15px 15px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

}

.listItem {
    height: 75px;
    border-bottom: 1px solid #D5D5D5;
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
    align-items: center;
}

.listItemLeft {
    display: flex;
    flex-direction: column;
}

.timeDetails {
    display: flex;
    gap: 40px;
}
